
function showEditProductsModal(button) {

    // Get the data attributes from the button
    const product = {
        id: button.dataset.productId,
        name: button.dataset.productName,
        profit: button.dataset.productProfit,
        profit_by_percentage: button.dataset.productProfit_by_percentage,
        discount: button.dataset.productDiscount,
        discount_by_percentage: button.dataset.productDiscount_by_percentage,
        qty_is_fixed: Boolean(button.dataset.productQty_is_fixed),
        default_profit: button.dataset.productDefault_profit,
        default_profit_unit: button.dataset.productDefault_profit_unit,
        default_profit_type: button.dataset.productDefault_profit_type,
        enabled: Boolean(button.dataset.productEnabled),
    };

    console.debug(product, button.dataset);

    document.querySelector('#product_enabled').checked = product.enabled;
    // Get the form element and update its action attribute
    const form = document.querySelector('#showEditProductsForm');

    form.action = form.action.replace(/\/\d+$/, "/" + product.id);

    // const formInputs = form.querySelectorAll('#qty_is_fixed input,option,select');
    // if (formInputs) {
    //     for (let i = 0; i < formInputs.length; i++) {
    //         formInputs[i].disabled = !product.qty_is_fixed;
    //     }
    // }
    const formMsg = document.getElementById('formMsg');
    const formMsgQtyIsFix = document.querySelector('#qty_is_fixed');
    // formMsgQtyIsFix.hidden = !product.qty_is_fixed;
    if (product.qty_is_fixed) {

        formMsg.innerText =
            "Adjust pricing for just this product, keep fields blank to use global default settings.";
        // Fill the form inputs with the product attributes
        document.querySelector('#product_profit').value = product.profit;
        document.querySelector('#product_discount').value = product.discount;


        let select = form.querySelector('select');
        let options = select.options;
        for (let i = 0; i < options.length; i++) {
            options[i].removeAttribute('selected');
        }

        // Select the corresponding options based on the data attributes
        let profitUnitOption = document.querySelector(
            `#product_profit_by_percentage option[value="${product.profit_by_percentage}"]`);
        if (profitUnitOption) {
            profitUnitOption.setAttribute("selected", true);
        }

        const discountUnitOption = document.querySelector(
            `#product_discount_by_percentage option[value="${product.discount_by_percentage}"]`);

        if (discountUnitOption) {
            discountUnitOption.setAttribute("selected", true);
        }

    } else {

        formMsg.innerText =
            "Enter percentage of network commission to retain as profit, the rest will be added to the user as discount, keep fields blank to use global default settings.";
        document.querySelector('#product_profit').value = product.profit;
        document.querySelector('#product_discount').value = "";



        let select = form.querySelector('select');
        let options = select.options;
        for (let i = 0; i < options.length; i++) {
            options[i].removeAttribute('selected');
        }

        // Select the corresponding options based on the data attributes
        let profitUnitOption = document.querySelector(
            `#product_profit_by_percentage option[value="1"]`);
        if (profitUnitOption) {
            profitUnitOption.setAttribute("selected", true);
        }



    }

    let discountDiv = document.querySelector(
        `#discount_field_div`);

    if (discountDiv){
        discountDiv.hidden = !product.qty_is_fixed;
    }

    let profitUnitOption0 = document.querySelector(
        `#product_profit_by_percentage option[value="0"]`);

    if (profitUnitOption0) {
        profitUnitOption0.disabled = !product.qty_is_fixed;
    }

    // Fill hidden input value with product ID
    let productInput = document.querySelector('#showEditProductsForm input[name="product"]');
    productInput.value = product.id;
    // Fill hidden input value with profit type
    let profitType = document.querySelector('#showEditProductsForm input[name="type"]');
    profitType.value = product.default_profit_type;

    let defaultProfitSpan = document.querySelector("#product_default_profit");
    if (defaultProfitSpan) {
        defaultProfitSpan.innerText = (product.default_profit !== "") ?
            `Defaults to global ${product.default_profit_type} ${product.default_profit} ${product.default_profit_unit} profit` : "";
    }

    const modalProductName = document.getElementById('modalProductName');
    modalProductName.innerText = product.name;

    const modal = new bootstrap.Modal(document.getElementById('showEditProducts'));
    modal.show();

}

function showAddProductsModal(button) {
    // Get the button that was clicked
    // console.log(button);
    const modal = new bootstrap.Modal(document.getElementById('addProducts' + button.dataset.serviceId));
    modal.show();
}

let productButtons = document.querySelectorAll('button[data-service-id]');
productButtons.forEach(button => {
    button.addEventListener('click', () => {
        // console.log(button);
        showAddProductsModal(button);
    });
});

let productEditButtons = document.querySelectorAll('label[data-product-id]');
productEditButtons.forEach(button => {
    button.addEventListener('click', () => {
        // console.log(button);
        showEditProductsModal(button);
    });
});
